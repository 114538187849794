<template>
  <div class="ctk-list-header tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-justify-between">
    <div class="ctk-list-header__container tw-flex tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-between tw-flex-1">
      <div
        data-test="titles"
        class="ctk-list-header__titles tw-flex tw-flex-1"
      >
        <div class="tw-truncate">
          <h1
            v-text="title"
            data-test="title"
            class="ctk-list-header__titles__title tw-truncate"
          />
          <h2
            v-text="subtitle"
            data-test="subtitle"
            class="ctk-list-header__titles__subtitle tw-truncate"
          />
        </div>
        <div class="ctk-list-header__inline-actions tw-hidden md:tw-flex tw-ml-4">
          <slot
            name="additional-actions"
          />
        </div>
      </div>
      <slot />
    </div>
    <div class="ctk-list-header__block-actions md:tw-hidden tw-mb-2">
      <slot
        name="additional-actions"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * Component used as the header of generic lists, containing the main title
   * of the page.
   * @module component - CtkListHeader
   * @param {string} title
   * @param {string} subtitle
   */
  export default defineComponent({
    name: 'CtkListHeader',
    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-list-header {
    position: relative;
    border-bottom: 1px solid $gray;

    &,
    &__wrapper {
      min-height: 87px;
    }

    &__titles {
      margin-top: 20px;

      &__title {
        color: $primary-text;
        font-size: 1.7em;
        font-weight: bold;
        margin-bottom: 0.2em;
      }

      &__subtitle {
        color: $secondary-text;
        font-weight: 400;
        font-size: 1em;
      }
    }
  }

</style>
